<template>
  <div class="d-flex flex-wrap justify-content-center align-items-stretch mct-calendar2 w-100">
      <div v-for="(mes,indexMes) in calendario" :key="'cd'+indexMes" class="mct-calendar2-mes">
        <h1>{{mes.mesExt}}</h1>
        <div class="d-flex flex-wrap justify-content-center mct-calendar2-dia">
          <h1 class="mct-calendar2-dia-sem">DOM</h1>          
          <h1 class="mct-calendar2-dia-sem">SEG</h1>          
          <h1 class="mct-calendar2-dia-sem">TER</h1>          
          <h1 class="mct-calendar2-dia-sem">QUA</h1>          
          <h1 class="mct-calendar2-dia-sem">QUI</h1>          
          <h1 class="mct-calendar2-dia-sem">SEX</h1>          
          <h1 class="mct-calendar2-dia-sem">SÁB</h1>
          <h1 v-for="(dia,indexDiaB) in onGeraDiasBranco(mes.dataIni,'I')" :key="'cdb'+indexDiaB" class="mct-calendar2-dia-branco"></h1>          
          
          <h1 v-for="(dia,indexDia) in mes.dias" :key="'cdd'+indexDia" 
            :class="(dia.legenda1 && dia.legenda1.indexOf('FERIADO') > -1) || (dia.legenda2 && dia.legenda2.indexOf('FERIADO') > -1) || (dia.legenda3 && dia.legenda3.indexOf('FERIADO') > -1) ? 'mct-calendar2-dia-fer' : dia.diasem == 0 ? 'mct-calendar2-dia-dom' : ''">
            <a href="#" @click.prevent.stop="showPopUp(indexDia,indexMes)">{{dia.dia}}</a>
          </h1>

          <h1 v-for="(dia,indexDiaB) in onGeraDiasBranco(mes.dataFin,'F')" :key="'cdbb'+indexDiaB" class="mct-calendar2-dia-branco"></h1>
        </div>
      </div>
      <div id="mct-calendar-popup" class="overlay" style="display:none">
        <div class="mct-calendar2-popup" v-if="indexSelMes >= 0 && indexSel >= 0">
          <div class="mct-calendar2-popup-left float-left" :class="calendario[indexSelMes].dias[indexSel].diasem == 0 ? 'mct-calendar2-popup-dom' : ''">
            <span class="mct-calendar2-popup-mes">{{moment(calendario[indexSelMes].dias[indexSel].data).locale("pt-br").format('MMMM').toUpperCase()}}</span>
            <span class="mct-calendar2-popup-dia">{{moment(calendario[indexSelMes].dias[indexSel].data).locale("pt-br").format('DD')}}</span>
            <span class="mct-calendar2-popup-diasem">{{moment(calendario[indexSelMes].dias[indexSel].data).locale("pt-br").format('dddd').toUpperCase()}}</span>
            <div class="text-center">
              <div class="m-0 btn-group" role="group" aria-label="Ações">
                <button type="button" class="btn btn-sm btn-success" aria-label="Left Align"  @click.prevent.stop="onSalvar(calendario[indexSelMes].dias[indexSel])">
                        <i class="fa fa-check fa-lg text-white" aria-hidden="true"></i> Gravar</button>
                <button type="button" class="btn btn-sm btn-danger" aria-label="Left Align" @click.prevent.stop="hiddenPopUp()">
                        <i class="fa fa-ban fa-lg text-white" aria-hidden="true"></i> Cancelar</button>
              </div>
            </div>
          </div>
          <div class="mct-calendar2-popup-right float-right">
            <a class="close float-right" href="#" @click.prevent.stop="hiddenPopUp()">&times;</a>
            <div class="form-group m-0 p-0">
              <label class="m-0 p-0" for="cal-legenda">Legenda:</label>  
              <select name="select" class="form-control m-0 p-0 text-left" v-model="calendario[indexSelMes].dias[indexSel].legenda1">
                <option value=""></option>
                <option v-for="(tipo,index) in TpCalendario" :value="tipo.tddac_legenda" :key="'cd1'+index">{{tipo.tddac_legenda}}</option> 
              </select> 
            </div>
            <div class="form-group m-0 p-0">
              <label class="m-0 p-0" for="cal-legenda">Legenda:</label>  
              <select name="select" class="form-control m-0 p-0 text-left" v-model="calendario[indexSelMes].dias[indexSel].legenda2">
                <option value=""></option>
                <option v-for="(tipo,index) in TpCalendario" :value="tipo.tddac_legenda" :key="'cd2'+index">{{tipo.tddac_legenda}}</option> 
              </select> 
            </div>
            <div class="form-group m-0 p-0">
              <label class="m-0 p-0" for="cal-legenda">Legenda:</label> 
              <select name="select" class="form-control m-0 p-0 text-left" v-model="calendario[indexSelMes].dias[indexSel].legenda3">
                <option value=""></option>
                <option v-for="(tipo,index) in TpCalendario" :value="tipo.tddac_legenda" :key="'cd3'+index">{{tipo.tddac_legenda}}</option> 
              </select> 
            </div>
          </div>
        </div>
      </div>

  </div>
</template>
<script>
  import Vue from 'vue'
  import moment from 'moment'
  
  import axios from '@/http'
  import { mapState } from 'vuex'

  export default {
    name: 'EscCalendarioLista',
    components: {

    },        
    props: ['source', 'titulo', 'showModal'],
    data() {
      return {
        message: '',
        status: 0,
        errors: {},        
        totalDias: 0,
        calendario: [],
        TpCalendario: [],
        diasGravados: [],
        indexSel: -1,
        indexSelMes: -1        
      }
    },
    methods: {
      hiddenPopUp () {
        $('.overlay').hide();
      },
      showPopUp (indexDia,indexMes) {
        this.indexSel = indexDia
        this.indexSelMes = indexMes
        $(".overlay").show();
      },
      onGeraDiasBranco (dia,tp) {
        let diasem = 0
        if (typeof dia !== 'undefined'){
          diasem = moment(dia).format('e')
        }
        let ret = []
        if (tp == 'I'){
          while (diasem > 0){
            ret.push({diasem: diasem})    
            diasem--
          }
        }else{
          while (diasem < 6){
            ret.push({diasem: diasem})    
            diasem++
          }
        }

        return ret
      }, 
      onGeraCalendario () {
        let year = 0;
        if (this.infoEscola.esc_tipo !== 1){
          year = moment().format('YYYY')
        } else {
          year = this.Exercicio
        }

        this.calendario = []
        let dataIni = ''
        let dataFin = ''

        for (let j = 1; j <= 12; j++){
          dataIni = moment(year+"-"+j+"-01")
          if(j == 12){
            var ano = Number(year)+1
            dataFin = moment(ano+"-01-01").subtract(1, 'days')
          }else{
            var mes = Number(j)+1 
            dataFin = moment(year+"-"+mes+"-01").subtract(1, 'days')
          }
          //console.log(dataIni,dataFin)

          let dias = moment(dataFin).diff(dataIni,'days')
          dias++
          this.calendario.push({
            mes: moment(dataIni).locale("pt-br").format('MM'),
            mesExt: moment(dataIni).locale("pt-br").format('MMMM').toUpperCase(),
            totalDias: dias,
            dataIni: dataIni,
            dataFin: dataFin,
            dias: []
          })          
          let dataBase = dataIni
          for (var i = 0; i < dias; i++){            
              var data = moment(dataBase).add(i, 'days')
              var jj = Number(j-1)

              var legenda1 = ''
              var legenda2 = ''
              var legenda3 = ''
              
              var diaGravado = this.diasGravados.filter(function(el) {
                //console.log(moment(el.calen_dia).locale("pt-br").format('DD/MM/YYYY'),moment(data).locale("pt-br").format('DD/MM/YYYY'));
                return moment(el.calen_dia).locale("pt-br").format('DD/MM/YYYY') == moment(data).locale("pt-br").format('DD/MM/YYYY');
              })

              if (typeof diaGravado[0] !== 'undefined'){
                if (typeof diaGravado[0]['calen_legenda_1'] !== 'undefined' && diaGravado[0]['calen_legenda_1']  !== ''){
                  legenda1 = diaGravado[0]['calen_legenda_1']
                }
                if (typeof diaGravado[0]['calen_legenda_2'] !== 'undefined' && diaGravado[0]['calen_legenda_2']  !== ''){
                  legenda2 = diaGravado[0]['calen_legenda_2']
                }
                if (typeof diaGravado[0]['calen_legenda_3'] !== 'undefined' && diaGravado[0]['calen_legenda_3']  !== ''){
                  legenda3 = diaGravado[0]['calen_legenda_3']
                }
              }
              this.calendario[jj]['dias'].push({
                seq: i+1, 
                data: data, 
                mes: moment(data).locale("pt-br").format('MM'),
                dia: moment(data).locale("pt-br").format('DD'),
                diasem: moment(data).locale("pt-br").format('e'),
                legenda1: legenda1,
                legenda2: legenda2,
                legenda3: legenda3
              })
          }
        }      
        
      },      
      async getCalendario () {
        var url = '/esccalendario/combo'       
        await axios.get(url)
          .then(response => {
              this.diasGravados = response.data.data
          })
          .catch(response => {
              //console.log(response)
          })
      },      
      async getTpCalendario () {
        var url = '/esctpcalendario/combo'       
        await axios.get(url)
          .then(response => {
              this.TpCalendario = response.data.data
          })
          .catch(response => {
              //console.log(response)
          })
      },
      async onSalvar(dia) {
        
        var URL = 'esccalendario/novo/dia'
        await axios.post(URL,dia)
          .then(response => {
              this.message = response.data.message
              this.status = response.status
              //this.lista = response.data.data
              this.errors = {}
              
              $('.overlay').hide()
              this.$swal('Sucesso!',
              'Dia incluído / Alterado com sucesso!',
              'success');  
          })
          .catch(response => {
              this.message = response.response.data.message
              if (response.response.data.error){
                  this.errors = response.response.data.error.errorInfo[2]              
              }else{
                  this.errors = response.response.data.errors
              }
              this.status = response.response.status
              this.setData()
              
          })
      },        
    },
    created () {
      this.getTpCalendario()
      this.getCalendario().then(() => this.onGeraCalendario());
    },
    updated () {
    },
    computed: {
      ...mapState('Login',['infoLogado','Entidade', 'Exercicio', 'Escola', 'infoEscola']), 
    }
  }
</script>